import { Heading3, PrimaryButton, Text } from "@cakemembers/components-core";
import { FeedItemEvent } from "@cakemembers/types";
import { SanityImageServer } from "@cakemembers/components-cake";
import { ItemHeader } from "./ItemHeader";
import { PostCTA } from "./PostCTA";
import Link from "next/link";

export function RenderEvent({ item }: { item: FeedItemEvent }) {
  const aspectRatio = item.image.asset.metadata?.dimensions?.aspectRatio || 1;
  return (
    <div className="flex flex-col">
      <ItemHeader creator={item.creator} createdAt={item._createdAt} />
      <Link href={item.shop_link} className="relative">
        <SanityImageServer
          image={item.image}
          alt={`Image for event`}
          height={aspectRatio * 500}
          width={aspectRatio * 500}
          className="w-full h-auto"
        />
      </Link>
      <div
        className="flex flex-row items-center lightSection rounded-b-md p-4"
        // Style prop was used in order to overrride the default bg coming from lightSection
        // And the lightSection was used in order to override the DarkSection in the Parent Component
        style={{
          backgroundColor: "#FDFCFA",
        }}
      >
        {item.title && <Heading3 className="grow">{item.title}</Heading3>}
        {item.shop_link && (
          <PrimaryButton href={item.shop_link} target="_blank self-end">
            RSVP
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}
