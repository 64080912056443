"use client";
import { LogoIcon } from "@cakemembers/components-cake";
import {
  CopyIcon,
  EmptyHeartIcon,
  GhostButton,
  HeartIcon,
  PrimaryButton,
  Text,
} from "@cakemembers/components-core";
import { CopyButton, MotionDiv } from "@cakemembers/components-motion";
import { isWebShareAvailable } from "@cakemembers/utils";
import { useMemo, useState } from "react";
import { getAccessInfo, getInvitationLink } from "../utils";
import { FlipInvitation } from "./FlipInvitation";
import { ShareButton } from "./ShareButton";

const defaultMessage =
  "You're invited to join CAKE, a private shopping community. Members receive VIP access to coveted brands, thousands of dollars to shop their current collections, and a whole world of special perks and invitations. You're going to love it.";

export function ShareScreen({
  returnHref,
  inviteCode,
  sponsorName,
  recipientName,
}: {
  returnHref: string;
  inviteCode: string;
  sponsorName: string;
  recipientName: string;
}) {
  const [copyClicked, setCopyClicked] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const canShare = useMemo(isWebShareAvailable, []);

  const link = getInvitationLink(inviteCode, sponsorName, recipientName);
  const accessInfo = getAccessInfo(inviteCode, sponsorName, recipientName);

  function copyText(includeLink = true) {
    return `${defaultMessage}
${accessInfo}${includeLink ? "\n\n" + link : ""}`;
  }

  const NotSharedInvitation = (
    <div className="h-[520px] p-3 bg-neutral">
      <div className="w-full h-full border border-white">
        <div className="pt-24 flex flex-col items-center h-full">
          <LogoIcon className="w-40 h-10 mb-10" fill="white" />

          <div className="relative mb-7">
            <div className="absolute top-[50%] -left-10 w-6 border-t h-[1px] border-[#7D766E]" />
            <Text
              size="2xl"
              weight="light"
              face="cursive"
              className="text-[#E4D6C8]"
            >
              for
            </Text>
            <div className="absolute top-[50%] -right-10 w-6 border-t h-[1px] border-[#7D766E]" />
          </div>

          <div className="flex flex-col items-center text-center gap-3 mb-20">
            <Text face="cursive" size="5xl">
              {recipientName}
            </Text>
            <Text
              face="sans"
              size="sm"
              className="tracking-[0.15em] leading-4 text-[#E4D6C8]"
            >
              VIP SHOPPING AWAITS
            </Text>
          </div>

          {canShare ? (
            <ShareButton
              url={link}
              text={() => copyText(false)}
              setIsShared={setIsShared}
            />
          ) : (
            <>
              <CopyButton
                text={copyText}
                icon={<CopyIcon />}
                iconPosition="right"
                onClick={() => {
                  setCopyClicked(true);
                  setIsShared(true);
                }}
              >
                Copy Invitation
              </CopyButton>
              {copyClicked && (
                <MotionDiv initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <Text className="text-primary/60">
                    Invite Copied. Paste into Text, Email, or other message
                  </Text>
                </MotionDiv>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );

  const SharedInvitation = (
    <div className="h-[520px] p-3 w-full bg-neutral">
      <div className="w-full h-full border border-white">
        <div className="pt-14 flex flex-col text-center items-center h-full">
          <LogoIcon className="w-40 h-10 mb-3" fill="white" />
          <Text
            face="sans"
            size="sm"
            className="tracking-[0.15em] leading-4 text-[#E4D6C8] mb-10"
          >
            INVITATION SENT
          </Text>

          <Text face="cursive" size="5xl" weight="light" className="mb-10">
            You&apos;re a <br /> great friend.
          </Text>
          <EmptyHeartIcon className="w-10 h-10 text-secondary mb-8" />

          <PrimaryButton
            align="center"
            className="w-[200px] bg-transparent text-white h-[52px] text-xs mb-5"
            href={returnHref}
          >
            Dismiss
          </PrimaryButton>
          <GhostButton
            size="lg"
            className="underline font-apris text-base font-light tracking-wide"
            style={{ color: "#E4D6C8" }}
            onClick={() => setIsShared(false)}
          >
            Have a problem sharing?
          </GhostButton>
        </div>
      </div>
    </div>
  );

  return (
    <div className="px-6 mb-[100px] darkSection text-neutral-content">
      <FlipInvitation
        front={NotSharedInvitation}
        back={SharedInvitation}
        isFlipped={isShared}
      />
    </div>
  );
}
