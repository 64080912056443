import {
  Caption3,
  ExternalProductLinkHandler,
  RightArrow,
} from "@cakemembers/components-core";
import Link from "next/link";

export function PostCTA({
  link,
  cta,
  target,
  itemType,
}: {
  link: string;
  cta: string;
  target?: "_blank";
  itemType?: string;
}) {
  return (
    <div className="absolute bottom-0 w-full bg-black/30">
      {itemType === "slice" ? (
        <ExternalProductLinkHandler targetUrl={link}>
          <div className="p-[16px] w-full flex flex-row text-white items-center">
            <Caption3 className="grow">{cta}</Caption3>
            <RightArrow />
          </div>
        </ExternalProductLinkHandler>
      ) : (
        <Link
          href={link}
          className="p-[16px] w-full flex flex-row text-white items-center"
          target={target}
          scroll={false}
        >
          <Caption3 className="grow">{cta}</Caption3>
          <RightArrow />
        </Link>
      )}
    </div>
  );
}
