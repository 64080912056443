"use client";
import { PrimaryButton } from "@cakemembers/components-core";
import { isWebShareAvailable } from "@cakemembers/utils";

export function ShareButton({
  url,
  text,
  title,
  setIsShared,
}: {
  url: string;
  text: string | (() => string);
  title?: string;
  setIsShared: (isShared: boolean) => void;
}) {
  async function handleClick() {
    if (!isWebShareAvailable()) {
      return;
    }
    const shareText = typeof text === "string" ? text : text();
    await navigator.share({
      text: shareText,
      url,
    });
    setIsShared(true);
  }

  return (
    <PrimaryButton
      align="center"
      className="w-[200px] bg-transparent text-white h-[52px] text-xs"
      onClick={handleClick}
    >
      Share Invitation
    </PrimaryButton>
  );
}
