"use client";

import { useSearchParams } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const slideWidth = 132;
const spaceBetween = 5;

export function SwiperOrdersList({ children }: { children: React.ReactNode }) {
  const mappedChildren = React.Children.map(children, (child) => (
    <SwiperSlide>{child}</SwiperSlide>
  ));

  const searchParams = useSearchParams();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [slidesPerView, setSlidesPerView] = useState(1);

  useEffect(() => {
    const calculateSlidesPerView = () => {
      if (wrapperRef.current) {
        const calculatedSlidesPerView =
          wrapperRef.current.offsetWidth / (slideWidth + spaceBetween);

        setSlidesPerView(calculatedSlidesPerView);
      }
    };

    calculateSlidesPerView();

    window.addEventListener("resize", calculateSlidesPerView);
    return () => {
      window.removeEventListener("resize", calculateSlidesPerView);
    };
  }, [searchParams]);

  return (
    <div ref={wrapperRef}>
      <Swiper spaceBetween={spaceBetween} slidesPerView={slidesPerView}>
        {mappedChildren}
      </Swiper>
    </div>
  );
}
