import { Heading3, Text } from "@cakemembers/components-core";
import { FeedItemStory } from "@cakemembers/types";
import { SanityImageServer } from "@cakemembers/components-cake";
import { ItemHeader } from "./ItemHeader";
import { PostCTA } from "./PostCTA";
import { useSearchParams } from "next/navigation";

export function RenderStory({ item }: { item: FeedItemStory }) {
  const sp = useSearchParams();
  const view = sp.get("view");
  const aspectRatio = item.image.asset.metadata?.dimensions?.aspectRatio || 1;
  return (
    <div className="flex flex-col">
      <ItemHeader creator={item.creator} createdAt={item._createdAt} />
      <div className="relative">
        <SanityImageServer
          image={item.image}
          alt={`Image for story: ${item.title}`}
          height={aspectRatio * 500}
          width={aspectRatio * 500}
          className="w-full h-auto"
        />
        <PostCTA
          link={`?${view ? `view=${view}&` : ``}modal=post-detail&post=${
            item._id
          }`}
          cta="Read More"
        />
      </div>
      <div
        className="flex flex-col rounded-b-md p-4 lightSection"
        // Style prop was used in order to overrride the default bg coming from lightSection
        // And the lightSection was used in order to override the DarkSection in the Parent Component
        style={{
          backgroundColor: "#FDFCFA",
        }}
      >
        <Heading3>{item.title}</Heading3>
        <Text size="lg">{item.excerpt} </Text>
      </div>
    </div>
  );
}
