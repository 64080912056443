"use client";
import { FormState, PrimaryButton } from "@cakemembers/components-core";
import { MotionDiv } from "@cakemembers/components-motion";
import { AnimatePresence } from "framer-motion";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { useFormStatus } from "react-dom";

export function CreateInvitationBtn({
  formState,
  cta,
  formValid,
  secondaryButton,
  disabled,
}: {
  formState: FormState;
  cta: React.ReactNode;
  formValid: boolean;
  secondaryButton?: React.ReactNode;
  disabled?: boolean;
}) {
  const searchParams = useSearchParams();
  const view = searchParams.get("view");
  const inviteId = searchParams.get("inviteId");
  const router = useRouter();
  const [isExpanded, setIsExpanded] = useState(false);
  const status = useFormStatus();

  useEffect(() => {
    if (formState.status === "success") {
      setIsExpanded(true);
      setTimeout(() => {
        router.push(
          `?${
            view ? `view=${view}&` : ""
          }modal=share-invite&inviteId=${inviteId}&screen=share`
        );
      }, 100);
    }
  }, [formState]);

  return (
    <>
      <MotionDiv transition={{ duration: 0.3 }} layout layoutId="expandableBtn">
        <PrimaryButton
          className="h-12 text-sm w-[200px] mx-auto"
          align="center"
          loading={status.pending}
          disabled={disabled || !formValid}
        >
          Create Invitation
        </PrimaryButton>
      </MotionDiv>

      <AnimatePresence>
        {isExpanded && (
          <MotionDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
            key="overlay"
            className="absolute top-0 left-0 right-0 bottom-0 bg-white"
          />
        )}
        {isExpanded && (
          <div className="absolute top-0 left-0 right-0 bottom-0 px-6 pt-0 h-full flex justify-center items-center darkSection">
            <MotionDiv
              layoutId="expandableBtn"
              className="w-full h-full bg-neutral p-3"
            >
              <MotionDiv
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
                className="w-full h-full border border-white"
              />
            </MotionDiv>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}
