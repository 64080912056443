import { Text } from "@cakemembers/components-core";
import { ProfileConnection } from "@cakemembers/types";
import dayjs from "dayjs";
import Link from "next/link";
import { Avatar } from "../Avatar";

export function ItemHeader({
  creator,
  createdAt,
}: {
  creator: ProfileConnection;
  createdAt: string;
}) {
  return (
    <Link
      href={`/${creator.profile.username}`}
      className="flex items-center gap-2 rounded-t-md p-4 lightSection"
      // Style prop was used in order to overrride the default bg coming from lightSection
      // And the lightSection was used in order to override the DarkSection in the Parent Component
      style={{
        backgroundColor: "#FDFCFA",
      }}
    >
      <Avatar profile={creator.profile} size="sm" />
      <div className="flex flex-col">
        <Text
          face="sans"
          size="sm"
          weight="bold"
          uppercase={
            creator.profile.type === "brand" ||
            creator.profile.username === "cake"
          }
          className="leading-5 tracking-wide"
        >
          {creator.profile.displayName || creator.profile.username}
        </Text>
        <Text face="sans" className="text-[#AFADAA]" size="xs">
          {dayjs(createdAt).format("MMM D, YYYY")}
        </Text>
      </div>
    </Link>
  );
}
