import { FeedItem as FeedItemType } from "@cakemembers/types";
import { RenderSlice } from "./RenderSlice";
import { RenderNewToCake } from "./RenderNewToCake";
import { RenderFeatured } from "./RenderFeatured";
import { RenderComingSoon } from "./RenderComingSoon";
import { RenderStory } from "./RenderStory";
import { RenderEvent } from "./RenderEvent";

export function FeedItem({ item }: { item: FeedItemType }) {
  switch (item.postType) {
    case "new_to_cake":
      return <RenderNewToCake item={item} />;
    case "coming_soon":
      return <RenderComingSoon item={item} />;
    case "featured_brand":
      return <RenderFeatured item={item} />;
    case "slice":
      return <RenderSlice item={item} />;
    case "story":
      return <RenderStory item={item} />;
    case "event":
      return <RenderEvent item={item} />;
    case "unsupported":
      return <></>;
  }

  console.error("Unknown feed item type: ", item);
  return null;
}
