import { motion } from "framer-motion";
import styles from "./FlipInvitation.module.scss";

export function FlipInvitation({
  front,
  back,
  isFlipped,
}: {
  front: JSX.Element;
  back: JSX.Element;
  isFlipped: boolean;
}) {
  return (
    <div className={styles.FlipInvitation}>
      <div className={styles["flip-invitation"]}>
        <motion.div
          className={styles["flip-invitation-inner"]}
          initial={false}
          animate={{ rotateY: isFlipped ? 180 : 360 }}
          transition={{ duration: 0.6, animationDirection: "normal" }}
        >
          <div className={styles["flip-invitation-front"]}>{front}</div>
          <div className={styles["flip-invitation-back"]}>{back}</div>
        </motion.div>
      </div>
    </div>
  );
}
