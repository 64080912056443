"use client";

import { Caption3, EmailIcon, Heading4 } from "@cakemembers/components-core";
import { MotionDiv } from "@cakemembers/components-motion";
import Link from "next/link";

export function QuickInviteLink({
  href,
  count,
}: {
  href: string;
  count: number;
}) {
  return (
    <MotionDiv
      initial={{ opacity: 0, scale: 0.6 }}
      animate={{ opacity: 1, scale: [0.6, 1.03, 1] }} // First scale to 1.03, then back to 1
      transition={{
        opacity: { duration: 0.2 },
        scale: {
          duration: 1.0,
          times: [0, 0.2, 0.7],
          ease: ["easeOut", "easeIn"],
        },
      }}
      className="flex shine"
    >
      <Link
        href={href}
        className="darkSection bg-neutral text-neutral-content pl-2 py-1 rounded-sm"
      >
        <div className="flex items-center">
          <EmailIcon className="w-4 mr-[8px]" />
          <div className="flex flex-row items-center">
            <Heading4>{count}</Heading4>
            <Caption3 className="ml-[4px]">
              {count === 1 ? "INVITE" : `INVITES`}
            </Caption3>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="-my-3"
          >
            <path
              d="M9 6L15 12L9 18"
              stroke="#AFADAA"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Link>
    </MotionDiv>
  );
}
