"use client";

import { FormAction, Text } from "@cakemembers/components-core";
import { shareInviteAction } from "../actions";
import { CreateInvitationBtn } from "./CreateInvitationBtn";
import { useMemo } from "react";

const inviteCreateText = [
  "Who is your plus one?",
  "Who is the lucky person?",
  "Who deserves a bigger slice?",
  "Who would you like to invite?",
  "Whose day are we making?",
];

export function CreateInvitationForm({
  inviteId,
  view,
}: {
  inviteId: string;
  view: string;
}) {
  const inviteText = useMemo(
    () => inviteCreateText[Math.floor(Math.random() * inviteCreateText.length)],
    []
  );
  return (
    <FormAction
      cta="CREATE INVITATION"
      action={shareInviteAction.bind(undefined, view, inviteId)}
      submitButton={CreateInvitationBtn}
      className="px-4 pb-4 flex flex-col items-center gap-10 h-[520px] relative mb-[100px]"
      clearOnSuccess={false}
    >
      <Text size="5xl" className="text-center">
        {inviteText}
      </Text>
      <div className="border-b border-b-[#E4D6C8]">
        <input
          name="name"
          type="text"
          placeholder="First Name"
          className="h-14 text-center focus:outline-none font-apris text-4xl placeholder:text-4xl placeholder:text-[#E4D6C8] placeholder:font-apris placeholder:text-center w-full"
          required
        />
      </div>
    </FormAction>
  );
}
